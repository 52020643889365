const siteMetadata = {
    title: 'Blaze Magic',
    description:
        `With two decades entertaining, Blaze's family-friendly comedy magic will amuse and amaze your guests, giving them an unforgettable experience.
      Performing in the Gold Coast, Brisbane, and throughout Australia, Blaze provides entertainment for events from intimate family gatherings to corporate functions and huge festivals like The Gold Coast Show.
      Along with an amazing stage show filled with incredible magic, hilarious comedy, and lots of audience participation, Blaze can also provide roaming entertainment, going from group to group performing close-up, giving guests the chance to experience the magic right in front of them, and even in their own hands.`,
    menuItems: [
        {
            title: 'Home',
            link: '/',
        },
        {
            title: 'Locations',
            link: '#',
            children: [
                {
                    title: 'QUEENSLAND',
                    link: '/queensland/'
                },
                {
                    title: 'Brisbane',
                    link: '/brisbane/'
                },
                {
                    title: 'Gold Coast',
                    link: '/goldcoast/'
                },


                {
                },
             
                {
                    title: 'NEW SOUTH WALES',
                    link: '/newsouthwales/'
                },
                {
                    title: 'Sydney',
                    link: '/sydney/'
                },

                {
                },
                {
                    title: 'VICTORIA',
                    link: '/victoria/'
                },

/*
                {
                },
                {
                    title: 'TASMANIA',
                    link: '/tasmania/'
                },


                {
                },
                


                {
                    title: 'SOUTH AUSTRALIA',
                    link: '/southaustralia/'
                },


                {
                },
                {
                    title: 'AUSTRALIAN CAPITAL TERRITORY',
                    link: '/australiancapitalterritory/'
                },


                {
                },
                {
                    title: 'WESTERN AUSTRALIA',
                    link: '/westernaustralia/'
                },


                {
                },
                {
                    title: 'NEW ZEALAND',
                    link: '/newzealand/'
                    
                },

                {
                },
                {
                    title: 'OVERSEAS',
                    link: '/overseas/'
                },

                */

            ]

            

        },

        

{
        title: 'Services',
        link: '#',
        children: [

            {
                title: 'Close-Up Roaming Magic',
                link: '/roaming/'
            },

            {

            },
            {
                title: 'Comedy Stage Magic',
                link: '/stage/'
            },

            {

            },

            {
                title: 'Magic Workshop',
                link: '/workshop/'
            },
/*
            {

            },

            {
                title: 'Custom Giveaways',
                link: '/workshop/'
            },
*/
        ]
    },


/*
    {
        title: '',
        link: '',
        children: [

        
        ]
    },

    */

/*
    {
        title: 'Events',
        link: '#',
        children: [

            {
                title: 'Weddings',
                link: '/overseas/'
            },
            {
                title: 'Corporate Events',
                link: '/overseas/'
            },
            {
                title: 'Trade Shows',
                link: '/overseas/'
            },
            {
                title: 'Schools',
                link: '/overseas/'
            },
            {
                title: 'Libraries',
                link: '/overseas/'
            },
            {
                title: 'Festivals',
                link: '/overseas/'
            },

            {
                title: 'Parties (Adults)',
                link: '/parties/'
            },
            {
                title: 'Parties (Children)',
                link: '/overseas/'
            },


        ]
    },
        */

    
        // {
        //     title: 'Services',
        //     link: '#',
        //     children: [
        //         {
        //             title: 'Kids Parties',
        //             link: '/kids-parties'
        //         },
        //         {
        //             title: 'Corporate Events',
        //             link: '/corporate-events'
        //         },
        //         {
        //             title: 'Festivals',
        //             link: '/festivals'
        //         },
        //         {
        //             title: 'Weddings',
        //             link: '/weddings'
        //         },
        //         {
        //             title: 'School Shows',
        //             link: '/school-shows'
        //         },
        //         {
        //             title: 'Balloon Twisting',
        //             link: '/balloon-twisting'
        //         },
        //     ]
        // }
    ]
};

module.exports = siteMetadata;
